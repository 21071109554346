import { Basket } from '@wix/bookings-services-preferences-modal';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { CloseDialogAction } from '../closeDialog/closeDialog';

export type OnServicesPreferencesConfirm = (basket: Basket) => void;

export const createOnServicesPreferencesConfirm =
  (
    { context }: ActionFactoryParams<CalendarState, CalendarContext>,
    closeDialog: CloseDialogAction,
  ): OnServicesPreferencesConfirm =>
  (basket) => {
    const { wixSdkAdapter, experiments } = context;

    closeDialog();

    wixSdkAdapter.navigateToBookingsCalendarPage(
      basket,
      {},
      experiments.enabled('specs.bookings.RouterPrefixNavigation'),
    );
  };
