import { ServiceType, ServiceLocationType } from '@wix/bookings-uou-types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  getDurationFromMinutes,
  getSlotDuration,
} from '../../../../utils/duration/duration';
import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';

export interface SummaryViewModel {
  openPreferencesButton: {
    text: string;
    ariaLabel: string;
  };
  summary: {
    services?: string;
    duration?: ReturnType<typeof getDurationFromMinutes>;
    location: string;
  };
}

export const memoizedSummaryViewModel: MemoizedViewModalFactory<SummaryViewModel> =
  {
    dependencies: {
      state: ['servicesInView', 'selectableSlotsAtSelectedTime'],
    },
    createViewModel: createSummaryViewModel,
  };

export function createSummaryViewModel({
  state,
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
}): SummaryViewModel {
  const { t } = context;

  return {
    openPreferencesButton: {
      text: t('app.summary.open-preferences-modal-button.text'),
      ariaLabel: t('app.summary.open-preferences-modal-button.aria-label'),
    },
    summary: {
      duration: getDurationSummary({ context, state }),
      services: getServicesSummary({ context, state }),
      location: getLocationSummary({ context, state }),
    },
  };
}

const getLocationSummary = ({
  context,
  state,
}: {
  state: CalendarState;
  context: CalendarContext;
}) => {
  const { t, calendarSelections } = context;
  const { servicesInView } = state;
  const service = servicesInView[0];

  const { location: locationId } = calendarSelections!;
  const location = locationId
    ? service.locations.find(
        ({ businessLocation }) => locationId === businessLocation?.id,
      )
    : service.locations[0];

  const locationType = location?.type;

  if (locationType === ServiceLocationType.OWNER_BUSINESS) {
    return location?.businessLocation?.name!;
  }

  if (locationType === ServiceLocationType.OWNER_CUSTOM) {
    return location?.locationText!;
  }

  if (locationType === ServiceLocationType.CUSTOM) {
    return t('app.booking-details.dropdowns.locations.client-place.text');
  }

  return '';
};

const getServicesSummary = ({
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
}) => {
  const { t, calendarSelections } = context;

  const { services } = calendarSelections!;
  const numberOfServices = services.length;

  return numberOfServices > 1
    ? t('app.summary.selected-services', { numberOfServices })
    : undefined;
};

const getDurationSummary = ({
  context,
  state,
}: {
  state: CalendarState;
  context: CalendarContext;
}) => {
  const { servicesInView, selectableSlotsAtSelectedTime } = state;
  const { t, calendarSelections, businessInfo } = context;

  const { services } = calendarSelections!;
  const dateRegionalSettingsLocale = businessInfo!.regionalSettingsLocale!;

  const isSomeServiceGroup = servicesInView.some(
    ({ info }) => info.type === ServiceType.GROUP,
  );

  if (isSomeServiceGroup) {
    if (!selectableSlotsAtSelectedTime?.length) {
      return undefined;
    }

    const [{ slot: firstSlot }] = selectableSlotsAtSelectedTime;
    const { startDate, endDate } = firstSlot!;

    const isAllSlotDurationEqual = selectableSlotsAtSelectedTime.every(
      ({ slot }) => slot?.startDate === startDate && slot?.endDate === endDate,
    );

    if (!isAllSlotDurationEqual) {
      return undefined;
    }

    return getSlotDuration({
      dateRegionalSettingsLocale,
      t,
      rfcStartTime: startDate!,
      rfcEndTime: endDate!,
    });
  }

  const totalDurationInMinute = services.reduce((total, { id }) => {
    const service = servicesInView.find(
      (serviceInView) => serviceInView.id === id,
    );
    const duration = service?.info.duration || 0;

    return total + duration;
  }, 0);

  return getDurationFromMinutes({
    dateRegionalSettingsLocale,
    minutes: totalDurationInMinute,
    t,
  });
};
